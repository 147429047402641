import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserAPI from '../api/User';
import { Alert, Button, Container, Form, Row, Card, Col } from 'react-bootstrap';
import Auth from '../authentication/auth';

export default class Login extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  state = {
    message: '',
    username: '',
    password: '',
  };

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  login = event => {
    event.preventDefault();
    const { username, password } = this.state;
    UserAPI.login({ login: username, password })
      .then(({ token, role, login }) => {
        Auth.authenticate(token, role, login);
        this.props.history.push({ pathname: '/channels' });
      })
      .catch(error => {
        if (!error.response || !error.response.data) {
          console.log('Error during /login: ', error);
        } else {
          this.setState({
            message: <Alert variant="danger">{error.response.data.message}</Alert>,
          });
        }
      });
  };

  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={6}>
            <Card>
              <Card.Header>
                <h5>Login</h5>
              </Card.Header>
              <Card.Body className="bg-light">
                <Form onSubmit={this.login}>
                  {this.state.message}
                  <Form.Group>
                    <Form.Control
                      required
                      type="text"
                      name="username"
                      placeholder="Username"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      required
                      name="password"
                      type="password"
                      placeholder="Password"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="btn-block">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
