import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'react-bootstrap';

function filterByStatus(actions, status, isAdmin) {
  return actions.filter(({ authorizedStatus, onlyAdmin }) => {
    return (
      (authorizedStatus.includes(status) || authorizedStatus.length === 0) &&
      (onlyAdmin ? isAdmin : true)
    );
  });
}

function SubscriptionActions({ actions, status, onAction, isAdmin }) {
  const authorizedActions = filterByStatus(actions, status, isAdmin);
  return (
    <ButtonToolbar className="float-right">
      {authorizedActions.map(action => (
        <Button
          key={action.action}
          className="ml-2"
          onClick={() => onAction(action.action)}
          variant={`inline-${action.variant}`}
        >
          {action.icon} {action.text}
        </Button>
      ))}
    </ButtonToolbar>
  );
}

SubscriptionActions.propTypes = {
  actions: PropTypes.array,
  status: PropTypes.string,
  onAction: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default SubscriptionActions;
