import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, Col, Form, Row, Tabs, Tab } from 'react-bootstrap';

import { FaExchangeAlt, FaTrash } from 'react-icons/fa';
import ExtractorForm from './extractors/ExtractorForm';
import AddExtractor from './extractors/AddExtractor';
import CodeEditor from './../../CodeEditor';
import AnonymizeInput from '../../AnonymizeInput';
import Help from '../../Help';
import WorkflowSection from './workflow/WorkflowSection';

export default class CallForm extends Component {
  static propTypes = {
    anonymize: PropTypes.object,
    availableCalls: PropTypes.array,
    body: PropTypes.string,
    extractors: PropTypes.array,
    headers: PropTypes.string,
    index: PropTypes.number,
    method: PropTypes.string,
    onAvailableVariablesClick: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    postWorkflow: PropTypes.array,
    url: PropTypes.string,
    workflow: PropTypes.array,
  };

  addExtractor = extractor => {
    this.props.onChange({
      target: {
        name: 'extractors',
        value: [...this.props.extractors, extractor],
      },
    });
  };

  updateExtractor = ({ target }, index) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.props.onChange({
      target: {
        name: 'extractors',
        value: this.props.extractors.map((extractor, currentIndex) => {
          if (currentIndex === index) {
            return { ...extractor, [name]: value };
          }
          return extractor;
        }),
      },
    });
  };

  deleteExtractor = deleteIndex => {
    this.props.onChange({
      target: {
        name: 'extractors',
        value: this.props.extractors.filter(
          (_extractor, index) => index !== deleteIndex
        ),
      },
    });
  };

  updateAnonymize = ({ target }) => {
    this.props.onChange({
      target: {
        name: 'anonymize',
        value: { ...this.props.anonymize, [target.name]: target.checked },
      },
    });
  };

  render() {
    const {
      index,
      method,
      url,
      body,
      headers,
      anonymize = {},
      onChange,
      extractors,
      workflow,
      postWorkflow,
      onAvailableVariablesClick,
      availableCalls,
    } = this.props;
    return (
      <Card className="call-card">
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h6>
                <FaExchangeAlt /> Call n°{index}
              </h6>
            </Col>
            <Col xs="auto">
              <Button
                onClick={this.props.onDelete}
                size="sm"
                variant="outline-light"
              >
                <FaTrash />
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Alert className="mb-4" variant="light" style={{ padding: 0 }}>
            <p>
              Throughout your configuration, you can use variables coming from
              channel’s columns and your extractors.
            </p>
            <Button
              variant="outline-primary"
              type="button"
              onClick={onAvailableVariablesClick}
            >
              See variables
            </Button>
          </Alert>
          <WorkflowSection
            label="Pre call workflow"
            name="workflow"
            workflow={workflow}
            onChange={onChange}
            availableCalls={availableCalls}
          />
          <Form.Row>
            <Form.Group as={Col} xs={2}>
              <Form.Label>Method*</Form.Label>
              <Form.Control
                as="select"
                required
                name="method"
                onChange={onChange}
                value={method}
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="PATCH">PATCH</option>
                <option value="DELETE">DELETE</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Url*</Form.Label>
              <Form.Control
                required
                type="text"
                name="url"
                placeholder="https://api.test.com/products/<%= columns.productId %>"
                onChange={onChange}
                value={url}
              />
            </Form.Group>
          </Form.Row>
          <Tabs variant="pills" className="tabs-call" defaultActiveKey="headers">
            <Tab eventKey="headers" title="Headers">
              <Form.Group>
                <CodeEditor
                  code={headers}
                  onChange={value =>
                    onChange({
                      target: { name: 'headers', value },
                    })
                  }
                />
                <br />
                <AnonymizeInput
                  checked={anonymize.headers}
                  name="headers"
                  onChange={this.updateAnonymize}
                  label="Anonymize Headers"
                  width={230}
                />
              </Form.Group>
            </Tab>
            <Tab eventKey="body" title="Body">
              <Form.Group>
                <CodeEditor
                  code={body}
                  onChange={value =>
                    onChange({
                      target: { name: 'body', value },
                    })
                  }
                />
                <br />
                <AnonymizeInput
                  checked={anonymize.body}
                  name="body"
                  onChange={this.updateAnonymize}
                  label="Anonymize Body"
                  width={210}
                />
              </Form.Group>
            </Tab>
          </Tabs>
          <Form.Group>
            <Form.Label>
              Extractors*&nbsp;
              <Help title="Extractors">
                <p>
                  Extractors help you extract data from call’s response body or
                  headers to reuse in other calls config.
                </p>
                <p>
                  <b>Path</b> describe how to extract data from JSON response.
                </p>
                <p>
                  <i>
                    Under the hood, we are using JSONata, checkout “Test it” for more
                    informations.
                  </i>
                </p>
              </Help>
            </Form.Label>
            {extractors.map((extractor, index) => (
              <div key={index}>
                <ExtractorForm
                  {...extractor}
                  onChange={e => this.updateExtractor(e, index)}
                >
                  <Col xs="auto">
                    <Button
                      type="button"
                      size="lg"
                      variant="inline-danger"
                      onClick={() => this.deleteExtractor(index)}
                    >
                      <FaTrash />
                    </Button>
                  </Col>
                </ExtractorForm>
                <br />
              </div>
            ))}
            <AddExtractor onAdd={this.addExtractor} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Response</Form.Label>
            <AnonymizeInput
              name="response"
              checked={anonymize.response}
              onChange={this.updateAnonymize}
              label="Anonymize Response"
              width={240}
            />
          </Form.Group>
          <WorkflowSection
            label="Post call workflow"
            name="postWorkflow"
            workflow={postWorkflow}
            onChange={onChange}
            availableCalls={availableCalls}
          />
        </Card.Body>
      </Card>
    );
  }
}
