import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import Columns from '../columns/Columns';

export default class SftpChannelForm extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      meta: PropTypes.object,
      groupBy: PropTypes.string,
      columns: PropTypes.array,
    }),
    onChange: PropTypes.func,
    onMetaChange: PropTypes.func,
  };

  onColumnsChange = event => {
    const { onChange, channel } = this.props;
    // Propagate event to parent
    onChange(event);

    // Check if groupBy column still exist in columns
    const hasGroupByColum = event.target.value.some(
      ({ name }) => name === channel.groupBy
    );
    if (channel.groupBy !== '' && !hasGroupByColum) {
      // If not reset it
      onChange({
        target: { name: 'groupBy', value: '' },
      });
    }
  };

  render() {
    const { channel, onChange, onMetaChange } = this.props;
    const { columns, groupBy, meta = {} } = channel;
    return (
      <>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Host*</Form.Label>
            <Form.Control
              as="input"
              required
              name="sftpHost"
              placeholder="remote-server.com"
              className={!meta.sftpHost ? 'select-default' : ''}
              onChange={onMetaChange}
              value={meta.sftpHost}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Port*</Form.Label>
            <Form.Control
              as="input"
              required
              name="sftpPort"
              placeholder="3001"
              className={!meta.sftpPort ? 'select-default' : ''}
              onChange={onMetaChange}
              value={meta.sftpPort}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Username*</Form.Label>
            <Form.Control
              as="input"
              required
              name="sftpUsername"
              placeholder="admin"
              className={!meta.sftpUsername ? 'select-default' : ''}
              onChange={onMetaChange}
              value={meta.sftpUsername}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Password*</Form.Label>
            <Form.Control
              as="input"
              type="password"
              required
              name="sftpPassword"
              className={!meta.sftpPassword ? 'select-default' : ''}
              onChange={onMetaChange}
              value={meta.sftpPassword}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Source path*</Form.Label>
            <Form.Control
              as="input"
              required
              name="sftpPath"
              placeholder="/home/admin/uploads"
              className={!meta.sftpPath ? 'select-default' : ''}
              onChange={onMetaChange}
              value={meta.sftpPath}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Format</Form.Label>
            <Form.Control
              as="select"
              name="fileFormat"
              className={!meta.fileFormat ? 'select-default' : ''}
              onChange={onMetaChange}
              value={meta.fileFormat}
            >
              <option value="">Select a format</option>
              <option value="csv">CSV / Excel</option>
              <option value="json">JSON</option>
              <option value="xml">XML</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <Columns columns={columns} onChange={this.onColumnsChange} />

        {columns.length > 0 && (
          <Form.Group>
            <Form.Label>Group by column (optional)</Form.Label>
            <Form.Control
              style={{ width: '25%' }}
              as="select"
              name="groupBy"
              className={!groupBy ? 'select-default' : ''}
              onChange={onChange}
              value={groupBy || 'default'}
            >
              <option value="default" disabled>
                Select a column
              </option>
              {columns.map(column => (
                <option key={column.id} value={column.name}>
                  {column.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
      </>
    );
  }
}
