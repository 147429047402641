import axios from 'axios';
import Auth from '../authentication/auth';

const API_URL = process.env.REACT_APP_API_HOST || 'http://localhost:3001';

const client = axios.create({
  baseURL: API_URL,
  json: true,
});

export default class BaseAPI {
  /**
   * Execute a HTTP Request
   *
   * @param {String} method The HTTP Method
   * @param {String} resource The Path
   * @param {Object} data An Object that will be stringified
   * @returns {Promise<Object>}
   */
  static async execute(method, resource, data) {
    const token = Auth.getToken();
    const options = {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: resource,
      data,
    };

    return client(options).then(req => {
      return req.data;
    });
  }
}
