import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import AnonymizeInput from './../../AnonymizeInput';

function ColumnForm({
  name,
  example,
  anonymize = false,
  onChange,
  children = null,
  required = true,
}) {
  return (
    <Form.Row>
      <Col xs={3}>
        <Form.Control
          required={required}
          type="text"
          name="name"
          value={name}
          placeholder="Name"
          onChange={onChange}
        />
      </Col>
      <Col xs={3}>
        <Form.Control
          type="text"
          name="example"
          value={example}
          placeholder="Example"
          onChange={onChange}
        />
      </Col>
      <Col xs={2}>
        <AnonymizeInput name="anonymize" checked={anonymize} onChange={onChange} />
      </Col>

      {children}
    </Form.Row>
  );
}

ColumnForm.propTypes = {
  name: PropTypes.string,
  example: PropTypes.string,
  anonymize: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.object,
  required: PropTypes.bool,
};

export default ColumnForm;
