import React from 'react';
import PropTypes from 'prop-types';
import { FaFileAlt } from 'react-icons/fa';
import { MdHttp } from 'react-icons/md';
import { Card, Col, Row } from 'react-bootstrap';

function ChannelTypes({ selectedType, onSelect }) {
  const channelClasses = (type, disabled = false) =>
    `channel-type ${selectedType === type ? 'channel-type-selected' : ''} ${
      disabled ? 'channel-type-disabled' : ''
    }`;
  return (
    <Row className="channel-types">
      <Col xs={3}>
        <Card className={channelClasses('sftp')} onClick={() => onSelect('sftp')}>
          <div className="channel-type-title">
            <FaFileAlt />
            <h5>SFTP</h5>
          </div>
          <p>Send a CSV, Excel, JSON or XML file using SFTP</p>
        </Card>
      </Col>
      <Col xs={3}>
        <Card className={channelClasses('rest')} onClick={() => onSelect('rest')}>
          <div className="channel-type-title">
            <MdHttp />
            <h5>REST API</h5>
          </div>
          <p>Send a JSON request using our REST API</p>
        </Card>
      </Col>
    </Row>
  );
}

ChannelTypes.propTypes = {
  selectedType: PropTypes.string,
  onSelect: PropTypes.func,
};

export default ChannelTypes;
