import React from 'react';
import PropTypes from 'prop-types';
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaQuestion } from 'react-icons/fa';

function Help({ placement = 'right', title, children, maxWidth = '276px' }) {
  const popover = (
    <Popover style={{ maxWidth }} title={title}>
      {children}
    </Popover>
  );
  return (
    <OverlayTrigger trigger="hover" placement={placement} overlay={popover}>
      <Badge pill variant="light">
        <FaQuestion />
      </Badge>
    </OverlayTrigger>
  );
}

Help.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.array,
  maxWidth: PropTypes.string,
};

export default Help;
