import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert, Card, Container, Form, Breadcrumb } from 'react-bootstrap';
import ChannelAPI from '../../api/Channel';
import SubscriptionAPI from '../../api/Subscription';
import UserAPI from '../../api/User';
import Auth from '../../authentication/auth';
import { NavLink } from 'react-router-dom';

export default class ImportSubscription extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  isAdmin = Auth.isAdmin();

  state = {
    subscription: {
      channel: { id: '' },
      subscriber: { id: '' },
    },
    channels: [],
    companies: [],
    uploadInput: null,
  };

  componentDidMount() {
    ChannelAPI.all().then(channels => {
      this.setState({
        channels: channels.filter(({ company }) => company.login !== this.login),
      });
    });
    if (this.isAdmin) {
      UserAPI.all().then(companies => {
        this.setState({
          companies,
        });
      });
    }
  }

  handleChannelChange = ({ target }) => {
    const channel = this.state.channels.find(channel => channel.id === target.value);
    this.setState(({ subscription }) => ({
      subscription: { ...subscription, channel },
    }));
  };

  handleSubscriberChange = ({ target }) => {
    const subscriber = this.state.companies.find(
      company => company.id === target.value
    );
    this.setState(({ subscription }) => ({
      subscription: { ...subscription, subscriber },
    }));
  };

  handleFileChange = uploadInput => {
    this.setState({ uploadInput, body: '' });
  };

  importSubscription = event => {
    event.preventDefault();

    const data = new FormData();
    data.append('file', this.state.uploadInput);
    data.append('filename', 'file' + Date.now());
    data.append('channel', this.state.subscription.channel.id);
    data.append('subscriber', this.state.subscription.subscriber.id);

    SubscriptionAPI.import(data)
      .then(data => {
        // Redirect to the /subscriptions/edit page, or /subscriptions if ID is missing
        if (!data.subscription || !data.subscription.id) {
          this.props.history.push({ pathname: `/subscriptions` });
        }
        this.props.history.push({
          pathname: `/subscriptions/edit/${data.subscription.id}`,
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          message: <Alert variant="danger">{err.response.data.message}</Alert>,
          uploadInput: null,
        });
      });
  };

  render() {
    const { subscription, channels, companies } = this.state;
    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/subscriptions">Subscription</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Import subscription</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>
            <h5>Import subscription</h5>
          </Card.Header>

          <Card.Body className="bg-light">
            <Form onSubmit={this.importSubscription}>
              <Form.Group>
                <Form.Label>Subscribe to *</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="channel.id"
                  className={!subscription.channel.id ? 'select-default' : ''}
                  onChange={this.handleChannelChange}
                  value={subscription.channel.id}
                >
                  <option value="" disabled>
                    Select a channel
                  </option>
                  {channels.map(channel => (
                    <option key={channel.id} value={channel.id}>
                      {channel.company.login} - {channel.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {this.isAdmin && (
                <Form.Group>
                  <Form.Label>Subscriber*</Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="subscriber"
                    className={!subscription.subscriber.id ? 'select-default' : ''}
                    onChange={this.handleSubscriberChange}
                    value={subscription.subscriber.id}
                  >
                    <option value="" disabled>
                      Select a subscriber
                    </option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.login}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>File*</Form.Label>
                <Form.Control
                  type="file"
                  required
                  ref={ref => (this.uploadInput = ref)}
                  onChange={event =>
                    this.handleFileChange(event.target.files.item(0))
                  }
                />
              </Form.Group>
              <Button className="btn-block" type="submit">
                Import & Ask approval
              </Button>
              {this.state.message}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
