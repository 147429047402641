import React from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import ChannelAPI from './../api/Channel';
import SubscriptionAPI from '../api/Subscription';
import TracingAPI from '../api/Tracing';
import { subHours, subWeeks } from 'date-fns';
import Auth from '../authentication/auth';

export default class Graph extends React.Component {
  state = {
    channels: [],
    subscriptions: [],
    links: [],
    timeFilter: 'last_hour',
  };

  palette = [
    '#05668D',
    '#9BC53D',
    '#4DAA57',
    '#171D1C',
    '#D1BEB0',
    '#FA7921',
    '#E55934',
    '#A53860',
    '#B892FF',
  ];

  intervalId = null;
  login = Auth.getLogin();
  isAdmin = Auth.isAdmin();

  getRandomColor() {
    const randomIndex = Math.floor(Math.random() * this.palette.length);
    const randomColor = this.palette[randomIndex] || '#000';
    this.palette.splice(randomIndex, 1);
    return randomColor;
  }

  componentDidMount() {
    Promise.all([ChannelAPI.all(), SubscriptionAPI.all()]).then(
      ([channels, subscriptions]) => {
        const relatedSubscriptions = subscriptions.filter(
          ({ subscriber, company }) =>
            subscriber.login === this.login ||
            company.login === this.login ||
            this.isAdmin
        );
        const relatedChannelsIds = relatedSubscriptions.map(
          ({ channel }) => channel.id
        );
        const relatedChannels = channels.filter(({ id }) =>
          relatedChannelsIds.includes(id)
        );
        const channelNodes = this.channelNodes(relatedChannels);
        const subscriptionNodes = this.subscriptionNodes(relatedSubscriptions);
        this.setState({
          channels: channelNodes,
          subscriptions: subscriptionNodes,
          links: this.channelLinks(channelNodes, subscriptionNodes),
        });
        this.intervalId = setInterval(this.updateCallCount, 1000);
      }
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getTimeFilter() {
    const { timeFilter } = this.state;
    if (timeFilter === 'last_hour') {
      return subHours(new Date(), 1);
    }
    if (timeFilter === 'last_day') {
      return subHours(new Date(), 24);
    }
    if (timeFilter === 'last_week') {
      return subWeeks(new Date(), 1);
    }
  }

  setTimeFilter = ({ target }) => {
    this.setState({ timeFilter: target.value });
  };

  updateCallCount = () => {
    TracingAPI.all({ end: this.getTimeFilter() }).then(tracings => {
      // Count by channel / subscribtions
      const count = tracings.reduce(
        (count, { channel, subscriber }) => {
          count.channels[channel.id]
            ? count.channels[channel.id]++
            : (count.channels[channel.id] = 1);
          const subscriptionId = `${channel.id}_${subscriber.id}`;
          count.subscriptions[subscriptionId]
            ? count.subscriptions[subscriptionId]++
            : (count.subscriptions[subscriptionId] = 1);
          return count;
        },
        { channels: {}, subscriptions: {} }
      );

      // Update call count
      this.setState(({ channels, subscriptions }) => ({
        channels: channels.map(channel => ({
          ...channel,
          callCount: count.channels[channel.id] || 0,
        })),
        subscriptions: subscriptions.map(subscription => ({
          ...subscription,
          callCount: count.subscriptions[subscription.id] || 0,
        })),
      }));
    });
  };

  channelNodes(channels) {
    return channels.map((channel, index) => ({
      id: channel.id,
      text: `${channel.company.login}  - ${channel.name}`,
      x: 10,
      y: ((index + 0.5) * 100) / channels.length, // distribute verticaly
      color: this.getRandomColor(),
      callCount: 0,
    }));
  }

  subscriptionNodes(subscriptions) {
    return subscriptions.map((subscription, index) => ({
      id: `${subscription.channel.id}_${subscription.subscriber.id}`,
      text: `${subscription.subscriber.login} - ${subscription.channel.name}`,
      x: 70,
      y: ((index + 0.5) * 100) / subscriptions.length, // distribute verticaly
      channelId: subscription.channel.id,
      callCount: 0,
    }));
  }

  channelLinks(channels, subscriptions) {
    return subscriptions.map(subscription => {
      const relatedChannel = channels.find(
        channel => channel.id === subscription.channelId
      );
      return {
        x1: relatedChannel.x + 3, // Slight margin
        y1: relatedChannel.y,
        x2: subscription.x + 10,
        y2: subscription.y,
        color: relatedChannel.color,
        calls: subscription.calls,
      };
    });
  }

  render() {
    const { channels, subscriptions, links, timeFilter } = this.state;

    return (
      <>
        <Container>
          <Row className="justify-content-center">
            <Form.Check
              checked={timeFilter === 'last_hour'}
              value="last_hour"
              className="mr-3"
              name="time-filter"
              type="radio"
              label="Last hour"
              onChange={this.setTimeFilter}
            />
            <Form.Check
              checked={timeFilter === 'last_day'}
              value="last_day"
              className="mr-3"
              name="time-filter"
              type="radio"
              label="Last day"
              onChange={this.setTimeFilter}
            />
            <Form.Check
              checked={timeFilter === 'last_week'}
              value="last_week"
              className="mr-3"
              name="time-filter"
              type="radio"
              label="Last week"
              onChange={this.setTimeFilter}
            />
          </Row>
        </Container>
        <Container fluid>
          <svg className="graph" viewBox="0 0 100 100">
            <g transform="translate(0 0) scale(1)">
              {channels.map(channel => {
                return (
                  <g key={channel.id}>
                    <text
                      textAnchor="right"
                      className="channel"
                      x="3"
                      y={channel.y - 4}
                    >
                      {channel.text}
                    </text>
                    <g transform={`translate(${channel.x}, ${channel.y})`}>
                      <circle
                        fill="#FFF"
                        stroke={channel.color}
                        className="calls"
                        r="3"
                      />
                      <text dy="0.3em" className="subscription" textAnchor="middle">
                        {channel.callCount}
                      </text>
                    </g>
                  </g>
                );
              })}
              {links.map((link, index) => (
                <g key={index}>
                  <path
                    stroke={link.color}
                    className="link"
                    d={`M${link.x1},${link.y1}C${(link.x1 + link.x2) / 2},${
                      link.y1
                    } ${(link.x1 + link.x2) / 2},${link.y2} ${link.x2},${link.y2}`}
                  />
                  <circle
                    stroke={link.color}
                    fill="#FFF"
                    className="calls"
                    cx={link.x2 + 3}
                    cy={link.y2}
                    r="3"
                  />
                </g>
              ))}
              {subscriptions.map(subscription => (
                <g key={subscription.id}>
                  <text
                    className="subscription"
                    x={subscription.x}
                    y={subscription.y - 4}
                  >
                    {subscription.text}
                  </text>
                  <text
                    className="subscription"
                    x={subscription.x + 10}
                    dx="0.9em"
                    y={subscription.y + 1}
                    textAnchor="middle"
                  >
                    {subscription.callCount}
                  </text>
                </g>
              ))}
            </g>
          </svg>
        </Container>
      </>
    );
  }
}
