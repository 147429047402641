import React, { Component } from 'react';
import {
  Alert,
  Table,
  Container,
  Card,
  Badge,
  Button,
  ButtonToolbar,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import {
  FaFilter,
  FaTimes,
  FaCheck,
  FaRegClock,
  FaChevronLeft,
  FaChevronRight,
  FaPollH,
  FaTrashAlt,
} from 'react-icons/fa';
import { differenceInMilliseconds, format } from 'date-fns';
import MonitoringFilters from './MonitoringFilters';
import TracingApi from './../../api/Tracing';
import CodeEditor from './../CodeEditor';
import Auth from '../../authentication/auth';

const POLL_INTERVAL = 1000;

export default class Monitoring extends Component {
  state = {
    total: 0,
    totalPages: 0,
    tracings: [],
    page: 1,
    detailTracing: '',
    showFilters: false,
    filters: {
      ownership: 'all',
    },
  };

  intervalId = null;

  isAdmin = Auth.isAdmin();
  login = Auth.getLogin();

  componentDidMount() {
    this.getTracings().then(() => {
      this.intervalId = setInterval(this.poll, POLL_INTERVAL);
    });
  }

  poll = () => {
    this.getTracings();
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  getTracings = () => {
    const { page, filters } = this.state;
    return TracingApi.all({
      page,
      ...filters,
    })
      .then(({ total, totalPages, tracings }) =>
        this.setState({ total, totalPages, tracings, message: '' })
      )
      .catch(({ message }) => {
        this.setState({
          message: (
            <Alert variant="danger">Failed to refresh tracings: {message}</Alert>
          ),
        });
      });
  };

  seeDetail = tracing => {
    this.setState({ detailTracing: JSON.stringify(tracing, null, 2) });
  };

  setPage = page => {
    this.setState({ page }, this.getTracings);
  };

  closeDetail = () => {
    this.setState({ detailTracing: '' });
  };

  deleteTracing = tracingId => {
    TracingApi.delete(tracingId).finally(() => {
      this.getTracings();
    });
  };

  toggleFilters = () => {
    this.setState(({ showFilters }) => ({ showFilters: !showFilters }));
  };

  applyFilter = (filterName, value) => {
    this.setState(({ filters }) => ({
      filters: { ...filters, [filterName]: value },
    }));
  };

  isSubscriber = tracing => {
    return tracing.subscriber ? tracing.subscriber.login === this.login : false;
  };

  render() {
    const {
      total,
      totalPages,
      page,
      tracings,
      detailTracing,
      showFilters,
      filters,
      message,
    } = this.state;
    return (
      <Container>
        {message}
        <Card>
          <Card.Header>
            <Row className="align-items-center">
              <Col>
                <h5>Monitoring</h5>
              </Col>
              <Col xs="auto">
                <Button
                  onClick={this.toggleFilters}
                  variant={showFilters ? 'primary' : 'light'}
                >
                  <FaFilter /> Filters
                </Button>
              </Col>
            </Row>
            {showFilters && (
              <MonitoringFilters filters={filters} onFilter={this.applyFilter} />
            )}
          </Card.Header>
          <Card.Body style={{ padding: 0 }}>
            <Table size="sm">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Channel</th>
                  <th>Subscriber</th>
                  <th>Success</th>
                  <th>Time</th>
                  <th>History</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {tracings.map(tracing => (
                  <tr key={tracing.id}>
                    <td>{format(tracing.startedAt, 'DD/MM/YYYY HH:mm:ss')}</td>
                    <td>
                      {tracing.channel ? tracing.channel.name : <i>Unknown</i>}
                    </td>
                    <td>
                      {tracing.subscriber ? (
                        tracing.subscriber.login
                      ) : (
                        <i>Unknown</i>
                      )}
                    </td>
                    <td>
                      {tracing.success ? (
                        <Badge pill className="badge-soft-success">
                          <FaCheck /> Success
                        </Badge>
                      ) : (
                        <Badge pill className="badge-soft-danger">
                          <FaTimes /> Failed
                        </Badge>
                      )}
                    </td>
                    <td>
                      <Badge pill variant="primary">
                        <FaRegClock />{' '}
                        {differenceInMilliseconds(
                          tracing.endedAt,
                          tracing.startedAt
                        )}
                        ms
                      </Badge>
                    </td>
                    <td>
                      {tracing.history.length} call
                      {tracing.history.length > 1 ? 's' : ''}
                    </td>
                    <td>
                      <ButtonToolbar className="float-right">
                        <Button
                          onClick={() => this.seeDetail(tracing)}
                          type="button"
                          variant="inline-primary"
                        >
                          <FaPollH /> Detail
                        </Button>
                        &nbsp;
                        {(this.isSubscriber(tracing) || this.isAdmin) && (
                          <Button
                            onClick={() => this.deleteTracing(tracing.id)}
                            variant="inline-danger"
                          >
                            <FaTrashAlt /> Delete
                          </Button>
                        )}
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
                {tracings.length === 0 && (
                  <tr>
                    <td>
                      <h6>No tracings yet.</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col>
                <p> {total} Items </p>
              </Col>
              <Col xs="auto ">
                <Button
                  className="btn-pagination"
                  size="sm"
                  variant="light"
                  hidden={page === 1}
                  onClick={() => this.setPage(page - 1)}
                >
                  <FaChevronLeft />
                </Button>
                <span className="p-3">Page n°{page}</span>
                <Button
                  className="btn-pagination"
                  size="sm"
                  variant="primary"
                  hidden={page === totalPages || totalPages === 0}
                  onClick={() => this.setPage(page + 1)}
                >
                  <FaChevronRight />
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
        <Modal show={detailTracing !== ''} onHide={this.closeDetail} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CodeEditor readOnly height="500px" code={detailTracing} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeDetail}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
