import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Auth from './../../authentication/auth';

function PrivateRoute({ component: Component, authorized = [], ...rest }) {
  const isLoggedIn = Auth.isLoggedIn();
  const role = Auth.getRole();
  const isAuthorized = authorized.includes(role) || authorized.length === 0;
  let redirect;
  if (!isLoggedIn) {
    redirect = '/login';
  }
  if (!isAuthorized) {
    redirect = '/';
  }
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn && isAuthorized ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirect} />
        )
      }
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  authorized: PropTypes.array,
};

export default PrivateRoute;
