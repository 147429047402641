import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BroadcastColumn from './broadcast/BroadcastColumn';
import BroadcastResponse from './broadcast/BroadcastResponse';

import ChannelAPI from '../api/Channel';
import BroadcastAPI from '../api/Broadcast';

export default class Broadcast extends Component {
  state = {
    isLoading: false,
    channels: [],
    channel: null,
    columns: [],
    responses: [],
  };

  getColumnsFromChannel(channel) {
    return channel.columns
      .split(/,|, |\n/g)
      .map(c => {
        return { label: c.trim(), value: '' };
      })
      .filter(c => c.label.length > 0);
  }

  handleChangeChannel = channelName => {
    const channel = this.state.channels.find(c => c.name === channelName);
    const columns = this.getColumnsFromChannel(channel);
    this.setState({ channel, columns });
  };

  handleColumnChange = (label, value) => {
    const columns = this.state.columns;
    const index = this.state.columns.findIndex(c => c.label === label);
    columns[index].value = value;
    this.setState({ columns });
  };

  handleBroadcast = () => {
    this.setState({ isLoading: true });
    const columns = {};
    this.state.columns.forEach(c => {
      columns[c.label] = c.value;
    });

    const data = {
      channel: this.state.channel.name,
      columns,
    };
    BroadcastAPI.broadcast(data)
      .then(responses => {
        this.setState({ responses, isLoading: false });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        console.error(err);
      });
  };

  componentDidMount() {
    ChannelAPI.all()
      .then(channels => {
        const [channel] = channels;
        const columns = this.getColumnsFromChannel(channel);
        this.setState({ channels, channel, columns });
      })
      .catch(err => {
        console.error(err);
      });
  }

  render() {
    return (
      <Container fluid={true}>
        <div className="ecosystem-div">
          <Row className="justify-content-md-center">
            <Col xs lg="7">
              <Form.Group>
                <Form.Control
                  id="select-channel"
                  as="select"
                  placeholder="Channel"
                  onChange={event => this.handleChangeChannel(event.target.value)}
                >
                  {this.state.channels.map(channel => {
                    return (
                      <option key={channel._id} value={channel.name}>
                        {channel.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg="3">
              {this.state.columns.map &&
                this.state.columns.map((column, index) => {
                  return (
                    <BroadcastColumn
                      key={index}
                      handleChange={this.handleColumnChange}
                      column={column}
                    />
                  );
                })}
            </Col>
            <Col md="auto">
              <Button onClick={this.handleBroadcast}>Broadcast</Button>
            </Col>
            <Col xs lg="3">
              {this.state.isLoading ? (
                <MDSpinner />
              ) : (
                this.state.responses.map(response => {
                  return (
                    <BroadcastResponse key={response.from} response={response} />
                  );
                })
              )}
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
