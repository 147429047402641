import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import PrivateRoute from './components/navigation/PrivateRoute';
import NavBar from './components/navigation/NavBar';

import Login from './components/Login';
import Channels from './components/channels/Channels';
import EditChannel from './components/channels/EditChannel';
import ImportChannel from './components/channels/ImportChannel';
import Subscriptions from './components/subscriptions/Subscriptions';
import NewSubscription from './components/subscriptions/NewSubscription';
import ImportSubscription from './components/subscriptions/ImportSubscription';
import ConfigureCalls from './components/subscriptions/calls/ConfigureCalls';
import Upload from './components/Upload';
import Monitoring from './components/monitoring/Monitoring';
import Graph from './components/Graph';
import Broadcast from './components/Broadcast';
import CreateUser from './components/CreateUser';
import Settings from './components/settings/Settings';

import './css/main.css';
import { ROLES } from './authentication/auth';
import FlashMessage from './components/FlashMessage';

export default class App extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <>
          <NavBar />
          <FlashMessage />
          <Switch>
            <Redirect exact from="/" to="/channels" />
            <Route path="/login" component={Login} />
            <PrivateRoute exact path="/channels" component={Channels} />
            <PrivateRoute exact path="/channels/create" component={EditChannel} />
            <PrivateRoute exact path="/channels/import" component={ImportChannel} />
            <PrivateRoute exact path="/channels/edit/:id" component={EditChannel} />

            <PrivateRoute exact path="/subscriptions" component={Subscriptions} />
            <PrivateRoute
              exact
              path="/subscriptions/create"
              component={NewSubscription}
            />
            <PrivateRoute
              exact
              path="/subscriptions/import"
              component={ImportSubscription}
            />
            <PrivateRoute
              exact
              path="/subscriptions/edit/:id"
              component={ConfigureCalls}
            />

            <PrivateRoute path="/upload" component={Upload} />
            <PrivateRoute path="/monitoring" component={Monitoring} />
            <PrivateRoute path="/graph" component={Graph} />
            <PrivateRoute path="/broadcast" component={Broadcast} />
            <PrivateRoute
              authorized={[ROLES.ADMIN]}
              path="/users/create"
              component={CreateUser}
            />
            <PrivateRoute
              authorized={[ROLES.ADMIN]}
              path="/settings"
              component={Settings}
            />
          </Switch>
        </>
      </Router>
    );
  }
}
