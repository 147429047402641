import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import ChannelAPI from '../../api/Channel';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

export default class ExportChannelButton extends Component {
  static propTypes = {
    channelId: PropTypes.string.isRequired,
  };

  exportChannel = event => {
    event.preventDefault();

    ChannelAPI.get(this.props.channelId).then(channel => {
      // Clear channel data useless for export
      channel = {
        ...channel,
        company: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        id: undefined,
      };
      if (channel.meta) {
        channel.meta = {
          ...channel.meta,
          lastStatus: undefined,
        };
      }
      // Convert to the object blob to allow download it
      const blob = new Blob([JSON.stringify(channel, null, 2)], {
        type: 'application/json',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${channel.slug}.channel.json`;
      a.click();
    });
  };

  render() {
    return (
      <Button
        className="float-right"
        onClick={this.exportChannel}
        variant="light"
        {...this.props}
      >
        <FaCloudDownloadAlt /> Export
      </Button>
    );
  }
}
