import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

function FlashMessage({ location }) {
  const flash = location.state && location.state.flash ? location.state.flash : null;

  return flash !== null ? (
    <p className="text-center">
      <Alert variant={flash.type}>{flash.message}</Alert>
    </p>
  ) : null;
}

FlashMessage.propTypes = {
  location: PropTypes.object,
};

export default withRouter(FlashMessage);
