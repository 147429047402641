import React, { Component } from 'react';
import SettingAPI from '../../api/Setting';
import { Alert, Button, Row, Col, Form } from 'react-bootstrap';

const MAILER_AUTH_TYPES = {
  LOGIN: 'login',
  OAUTH: 'OAuth2',
};
const RECIPIENT_EMAILS_SEPARATOR = /\s*[,;\r\n]\s*/;
const RECIPIENT_EMAILS_GLUE = ';';

const failAlert = message => (
  <Alert variant="danger">Failed to update mailer settings: {message}</Alert>
);

export default class MailerSettingForm extends Component {
  state = {
    setting: {},
    newSetting: {
      alertSenderEmail: '',
      alertRecipientsEmails: [],
      host: '',
      port: '',
      authType: '',
      user: '',
      pass: '',
      clientId: '',
      clientSecret: '',
      refreshToken: '',
      accessToken: '',
      expires: '',
      accessUrl: '',
    },
    isEdited: false,
    message: '',
  };

  componentDidMount() {
    SettingAPI.getMailer()
      .then(data => {
        if (data) {
          this.setState({ setting: data, newSetting: data, isEdited: false });
        }
      })
      .catch(err => {
        console.log(err);
        if (!err.response || !err.response.data) {
          return;
        }
        this.setState({
          message: <Alert variant="danger">{err.response.data.message}</Alert>,
        });
      });
  }

  handleInputChange = ({ target }) => {
    this.setState(({ setting, newSetting }) => {
      newSetting = { ...newSetting, [target.name]: target.value };
      return {
        newSetting: newSetting,
        // NOTE: Number are returned as string from inputs, so same number are still consider as edited (eg. port)
        isEdited: JSON.stringify(setting) !== JSON.stringify(newSetting),
      };
    });
  };

  handleRecipientsChange = ({ target }) => {
    this.setState(({ setting, newSetting }) => {
      newSetting = {
        ...newSetting,
        alertRecipientsEmails: target.value.split(RECIPIENT_EMAILS_SEPARATOR),
      };
      return {
        newSetting,
        isEdited: JSON.stringify(setting) !== JSON.stringify(newSetting),
      };
    });
  };

  updateSetting = event => {
    event.preventDefault();
    const { newSetting } = this.state;

    SettingAPI.putMailer(newSetting)
      .then(data => {
        this.setState({ setting: data, newSetting: data, isEdited: false });
      })
      .catch(err => {
        this.setState({ message: failAlert(err.message) });
      });
  };

  authForm = type => {
    const { newSetting } = this.state;
    switch (type) {
      case MAILER_AUTH_TYPES.LOGIN:
        return (
          <>
            <Form.Group>
              <Form.Label>User*</Form.Label>
              <Form.Control
                as="input"
                required
                name="user"
                className={!newSetting.user ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.user}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password*</Form.Label>
              <Form.Control
                as="input"
                required
                name="pass"
                className={!newSetting.pass ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.pass}
              />
            </Form.Group>
          </>
        );
      case MAILER_AUTH_TYPES.OAUTH:
        return (
          <>
            <Form.Group>
              <Form.Label>User*</Form.Label>
              <Form.Control
                as="input"
                required
                name="user"
                className={!newSetting.user ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.user}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Client ID*</Form.Label>
              <Form.Control
                as="input"
                required
                name="clientId"
                className={!newSetting.clientId ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.clientId}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Client secret*</Form.Label>
              <Form.Control
                as="input"
                required
                name="clientSecret"
                className={!newSetting.clientSecret ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.clientSecret}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Refresh token</Form.Label>
              <Form.Control
                as="input"
                name="refreshToken"
                className={!newSetting.refreshToken ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.refreshToken}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Access token</Form.Label>
              <Form.Control
                as="input"
                name="accessToken"
                className={!newSetting.accessToken ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.accessToken}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Expires</Form.Label>
              <Form.Control
                as="input"
                name="expires"
                className={!newSetting.expires ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.expires}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Access Url</Form.Label>
              <Form.Control
                as="input"
                name="accessUrl"
                className={!newSetting.accessUrl ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.accessUrl}
              />
            </Form.Group>
          </>
        );
      default:
        return;
    }
  };

  render() {
    const { newSetting, isEdited } = this.state;
    return (
      <Form onSubmit={this.updateSetting}>
        <Row>
          <Col>
            <h5>SMTP</h5>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Host*</Form.Label>
                <Form.Control
                  as="input"
                  required
                  name="host"
                  className={!newSetting.host ? 'select-default' : ''}
                  onChange={this.handleInputChange}
                  value={newSetting.host}
                />
              </Form.Group>
              <Form.Group as={Col} xs={3}>
                <Form.Label>Port*</Form.Label>
                <Form.Control
                  as="input"
                  required
                  name="port"
                  className={!newSetting.port ? 'select-default' : ''}
                  onChange={this.handleInputChange}
                  value={newSetting.port}
                />
              </Form.Group>
            </Form.Row>
            <Form.Group>
              <Form.Label>Auth type*</Form.Label>
              <Form.Control
                as="select"
                required
                name="authType"
                className={!newSetting.authType ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.authType}
              >
                <option value="" disabled>
                  Select a format
                </option>
                <option value={MAILER_AUTH_TYPES.LOGIN}>Login</option>
                <option value={MAILER_AUTH_TYPES.OAUTH}>OAuth2</option>
              </Form.Control>
            </Form.Group>
            {this.authForm(newSetting.authType)}
          </Col>
          <Col>
            <h5>Alert mail</h5>
            <Form.Group>
              <Form.Label>Sender*</Form.Label>
              <Form.Control
                as="input"
                required
                name="alertSenderEmail"
                className={!newSetting.alertSenderEmail ? 'select-default' : ''}
                onChange={this.handleInputChange}
                value={newSetting.alertSenderEmail}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Recipients*</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="alertRecipientsEmails"
                row="5"
                className={!newSetting.alertRecipientsEmails ? 'select-default' : ''}
                onChange={this.handleRecipientsChange}
                value={newSetting.alertRecipientsEmails.join(RECIPIENT_EMAILS_GLUE)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="btn-block" type="submit" disabled={!isEdited}>
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
