import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { FaCheck, FaBan } from 'react-icons/fa';
import SubscriptionStatus, { STATUS } from './SubscriptionStatus';
import SubscriptionActions from './SubscriptionActions';

const actions = [
  {
    action: 'approve',
    text: 'Approve',
    icon: <FaCheck />,
    variant: 'success',
    authorizedStatus: [STATUS.AWAITING_APPROVAL, STATUS.REJECTED],
  },
  {
    action: 'reject',
    text: 'Reject',
    icon: <FaBan />,
    variant: 'warning',
    authorizedStatus: [STATUS.AWAITING_APPROVAL, STATUS.APPROVED],
  },
];

function SubscriberList({ subscriptions = [], onAction }) {
  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Subscribers</h5>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ padding: 0 }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Channel</th>
              <th>Subscriber</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {subscriptions.map(subscription => (
              <tr key={subscription.id}>
                <td>{subscription.channel.name}</td>
                <td>{subscription.subscriber.login}</td>
                <td>
                  <SubscriptionStatus status={subscription.status} />
                </td>
                <td>
                  <SubscriptionActions
                    actions={actions}
                    status={subscription.status}
                    onAction={action => onAction(action, subscription.id)}
                  />
                </td>
              </tr>
            ))}
            {subscriptions.length === 0 && (
              <tr>
                <td>
                  <h6>No subscribers yet.</h6>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

SubscriberList.propTypes = {
  subscriptions: PropTypes.array,
  onAction: PropTypes.func,
};

export default SubscriberList;
