import React from 'react';
import PropTypes from 'prop-types';
import 'brace';
import 'brace/mode/javascript';
import 'brace/theme/github';
import AceEditor from 'react-ace';

const EDITOR_MODE = 'javascript';
const EDITOR_THEME = 'github';
const MIN_HEIGHT = 100;
const HEIGHT_PER_LINE = 20;

// Calculate height from code
const autoHeight = code => {
  const lineCount = (code.match(/\n/g) || []).length + 1; // Number of \n + last line
  const height = lineCount * HEIGHT_PER_LINE;
  return height > MIN_HEIGHT ? `${height}px` : `${MIN_HEIGHT}px`;
};

function CodeEditor({
  readOnly = false,
  mode = EDITOR_MODE,
  height,
  code = '',
  onChange,
}) {
  return (
    <AceEditor
      readOnly={readOnly}
      mode={mode}
      theme={EDITOR_THEME}
      fontSize="14px"
      height={height || autoHeight(code)}
      width="100%"
      value={code}
      onChange={onChange}
      editorProps={{
        $blockScrolling: Infinity,
      }}
    />
  );
}

CodeEditor.propTypes = {
  readOnly: PropTypes.bool,
  mode: PropTypes.string,
  height: PropTypes.string,
  code: PropTypes.string,
  onChange: PropTypes.func,
};

export default CodeEditor;
