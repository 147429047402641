import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import CodeEditor from '../CodeEditor';

function AvailableVariables({ variables, onClose }) {
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Available variables</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You can use these variables in your configuration :</p>
        <ul>
          {variables.map((variable, index) => (
            <li key={index}>
              <b>{variable}</b>
            </li>
          ))}
        </ul>
        <p>
          Using syntax <i>{'<%= variable %>'}</i>
        </p>
        <p>
          <u>Examples</u> :
        </p>
        <p>
          <b>Url</b> :&nbsp;
          {'https://api.test.com/products/<%= columns.productId %>'}
        </p>

        <p>
          <b>Body/Headers</b> : <br />
          <br />
          <CodeEditor
            readOnly
            code={JSON.stringify(
              { type: 'add', product: '<%= columns.productId %>' },
              null,
              2
            )}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AvailableVariables.propTypes = {
  variables: PropTypes.array,
  onClose: PropTypes.func,
};

export default AvailableVariables;
