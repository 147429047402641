import BaseAPI from './BaseAPI';

/**
 * User API
 * @extends BaseAPI
 */
export default class User extends BaseAPI {
  /**
   * Create a new User
   *
   * @returns {Promise<Object>} Creation result
   */
  static create(user) {
    return this.execute('post', '/users', user);
  }

  static getChannels() {
    return this.execute('get', '/users/current/channels');
  }

  static getSubscriptions() {
    return this.execute('get', '/users/current/subscriptions');
  }

  /**
   * Login the User
   *u
   * @param {Object} data The user login/password
   * @returns {Promise<Object>} the login response
   */
  static login(credentials) {
    return this.execute('post', '/login', credentials);
  }

  /**
   * Get users filter by optionnal role (restricted to admin in API)
   *
   * @returns {Promise<[Object]>} Users
   */
  static all(role = null) {
    return this.execute('get', `/users${role ? `?role=${role}` : ''}`);
  }
}
