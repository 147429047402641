import BaseAPI from './BaseAPI';

/**
 * Setting API
 * @extends BaseAPI
 */
export default class Setting extends BaseAPI {
  static get() {
    return this.execute('get', '/settings');
  }

  static getMailer() {
    return this.execute('get', '/settings/mailer');
  }

  static put(setting) {
    return this.execute('put', '/settings', setting);
  }

  static putMailer(mailerSetting) {
    return this.execute('put', '/settings/mailer', mailerSetting);
  }
}
