import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import routes from './routes';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../logo.png';
import Auth from '../../authentication/auth';

class NavBar extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  logout = () => {
    Auth.logout();
    this.props.history.push({ pathname: '/login' });
  };

  createUser = () => {
    this.props.history.push({ pathname: '/users/create' });
  };

  setting = () => {
    this.props.history.push({ pathname: '/settings' });
  };

  render() {
    const isLoggedIn = Auth.isLoggedIn();
    const login = Auth.getLogin();
    const isAdmin = Auth.isAdmin();

    const loggedInMenu = (
      <Nav className="nav mx-auto">
        {routes.map(route => (
          <Navbar.Text key={route.path}>
            {route.icon}
            <Link to={route.path}>{route.name}</Link>
          </Navbar.Text>
        ))}
        <NavDropdown
          id="login-dropdown"
          title={login}
          className="justify-content-end"
        >
          <NavDropdown.Item onClick={this.logout}>Disconnect</NavDropdown.Item>
          {isAdmin && (
            <>
              <NavDropdown.Item onClick={this.createUser}>
                Create User
              </NavDropdown.Item>
              <NavDropdown.Item onClick={this.setting}>Settings</NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Nav>
    );

    const anonymousMenu = (
      <Nav className="nav">
        <Navbar.Text>
          <Link to="/login">Login</Link>
        </Navbar.Text>
      </Nav>
    );

    return (
      <Navbar className="ecosystem-navbar">
        <Navbar.Brand>
          <div className="ecosystem-logo">
            <img alt="logo" src={logo} />
          </div>
        </Navbar.Brand>
        <Navbar.Collapse>
          {isLoggedIn ? loggedInMenu : anonymousMenu}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(NavBar);
