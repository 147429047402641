import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import SubscriptionAPI from '../../api/Subscription';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

export default class ExportSubscriptionButton extends Component {
  static propTypes = {
    subscriptionId: PropTypes.string.isRequired,
  };

  exportChannel = event => {
    event.preventDefault();

    SubscriptionAPI.get(this.props.subscriptionId).then(subscription => {
      let slug = subscription.channel.slug;
      if (subscription.subscriber.login) {
        slug += `_${subscription.subscriber.login}`;
      }
      // Clear subscription data useless for export
      subscription = {
        ...subscription,
        channel: undefined,
        company: undefined,
        subscriber: undefined,
        status: undefined,
        updatedAt: undefined,
        id: undefined,
      };
      // Convert to the object blob to allow download it
      const blob = new Blob([JSON.stringify(subscription, null, 2)], {
        type: 'application/json',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${slug}.subscription.json`;
      a.click();
    });
  };

  render() {
    return (
      <Button
        className="float-right"
        onClick={this.exportChannel}
        variant="light"
        {...this.props}
      >
        <FaCloudDownloadAlt /> Export
      </Button>
    );
  }
}
