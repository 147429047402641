import {
  FaBullhorn,
  FaFilter,
  FaCloudUploadAlt,
  FaPollH,
  FaCodeBranch,
} from 'react-icons/fa';
import React from 'react'; // Trick to use jsx component in route's icon

const routes = [
  {
    name: 'Channels',
    icon: <FaBullhorn className="nav-icon" />,
    path: '/channels',
  },
  {
    name: 'Subscriptions',
    icon: <FaFilter className="nav-icon" />,
    path: '/subscriptions',
  },
  {
    name: 'Monitoring',
    icon: <FaPollH className="nav-icon" />,
    path: '/monitoring',
  },
  {
    name: 'Graph',
    icon: <FaCodeBranch className="nav-icon" />,
    path: '/graph',
  },
  {
    name: 'Upload',
    icon: <FaCloudUploadAlt className="nav-icon" />,
    path: '/upload',
  },
];

export default routes;
