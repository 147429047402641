import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import {
  FaPlus,
  FaTrashAlt,
  FaExchangeAlt,
  FaCloudUploadAlt,
  FaCheck,
  FaBan,
} from 'react-icons/fa';
import SubscriptionStatus, { STATUS } from './SubscriptionStatus';
import SubscriptionActions from './SubscriptionActions';
import ExportSubscriptionButton from './ExportSubscriptionButton';

const actions = [
  {
    action: 'edit',
    text: 'Configure Calls',
    icon: <FaExchangeAlt />,
    variant: 'primary',
    authorizedStatus: [STATUS.APPROVED],
  },
  {
    action: 'delete',
    text: 'Delete',
    icon: <FaTrashAlt />,
    variant: 'danger',
    authorizedStatus: [],
  },

  {
    action: 'approve',
    text: 'Approve',
    icon: <FaCheck />,
    variant: 'success',
    authorizedStatus: [STATUS.AWAITING_APPROVAL, STATUS.REJECTED],
    onlyAdmin: true,
  },
  {
    action: 'reject',
    text: 'Reject',
    icon: <FaBan />,
    variant: 'warning',
    authorizedStatus: [STATUS.AWAITING_APPROVAL, STATUS.APPROVED],
    onlyAdmin: true,
  },
];

function SubscriptionList({ subscriptions = [], onAction, isAdmin }) {
  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>Subscriptions</h5>
          </Col>
          <Col xs="auto">
            <Button onClick={() => onAction('new')} variant="light">
              <FaPlus /> New Subscription
            </Button>
            &nbsp;
            <Button onClick={() => onAction('import')} variant="light">
              <FaCloudUploadAlt /> Import
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body style={{ padding: 0 }}>
        <Table size="sm">
          <thead>
            <tr>
              <th>Channel</th>
              <th>Company</th>
              {isAdmin && <th>Subscriber</th>}
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {subscriptions.map(subscription => (
              <tr key={subscription.id}>
                <td>{subscription.channel.name}</td>
                <td>{subscription.company.login}</td>
                {isAdmin && <td>{subscription.subscriber.login}</td>}
                <td>
                  <SubscriptionStatus status={subscription.status} />
                </td>
                <td>
                  <SubscriptionActions
                    actions={actions}
                    status={subscription.status}
                    onAction={action => onAction(action, subscription.id)}
                    isAdmin={isAdmin}
                  />
                  <ExportSubscriptionButton subscriptionId={subscription.id} />
                </td>
              </tr>
            ))}
            {subscriptions.length === 0 && (
              <tr>
                <td>
                  <h6>No subscriptions yet.</h6>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

SubscriptionList.propTypes = {
  subscriptions: PropTypes.array,
  onAction: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default SubscriptionList;
