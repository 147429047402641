import BaseAPI from './BaseAPI';

/**
 * Tracing API
 * @extends BaseAPI
 */
export default class Tracing extends BaseAPI {
  /**
   * Get all the tracings
   *
   * @param {Object} filter
   * @returns {Promise<Object>} All the tracings paginated
   */
  static all(filter = {}) {
    const searchParams = new URLSearchParams();
    Object.keys(filter).forEach(key => searchParams.append(key, filter[key]));
    return this.execute('get', `/tracings?${searchParams}`);
  }

  /**
   * Delete a tracing
   *
   * @param {String} id The tracing ID
   * @returns {Promise}
   */
  static delete(id) {
    return this.execute('delete', `/tracings/${id}`);
  }
}
