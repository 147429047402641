import React, { Component } from 'react';
import UserAPI from '../api/User';

import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

export default class CreateUser extends Component {
  state = {
    message: '',
    login: '',
    password: '',
  };

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  createUser = event => {
    event.preventDefault();
    const body = {
      login: this.state.login,
      password: this.state.password,
    };

    UserAPI.create(body)
      .then(data => {
        this.setState({
          message: <Alert variant="success">{data.message}</Alert>,
          login: '',
          password: '',
        });
      })
      .catch(err => {
        this.setState({
          message: <Alert variant="danger">{err.response.data.message}</Alert>,
          login: '',
          password: '',
        });
      });
  };

  render() {
    const { login, password } = this.state;
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={6}>
            <Card>
              <Card.Header>
                <h5>Create user</h5>
              </Card.Header>
              <Card.Body className="bg-light">
                <Form onSubmit={this.createUser}>
                  {this.state.message}
                  <Form.Group>
                    <Form.Control
                      type="text"
                      name="login"
                      value={login}
                      placeholder="Login"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      required
                      name="password"
                      type="password"
                      value={password}
                      placeholder="Password"
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                      as="select"
                      name="role"
                      placeholder="company"
                      value={role}
                      onChange={this.handleInputChange}
                    >
                      <option value="company">Company</option>
                      <option value="startup">Startup</option>
                    </Form.Control>
                  </Form.Group> */}
                  <Button variant="primary" type="submit" className="btn-block">
                    Create
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
