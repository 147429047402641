import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Badge,
  Button,
  Breadcrumb,
  Card,
  Container,
  Col,
  Form,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import Auth from '../../authentication/auth';
import RestChannelForm from './forms/RestChannelForm';
import SftpChannelForm from './forms/SftpChannelForm';
import ChannelAPI from '../../api/Channel';
import UserAPI from '../../api/User';
import ChannelStatus from './ChannelStatus';
import ChannelTypes from './ChannelTypes';
import ExportChannelButton from './ExportChannelButton';

const failAlert = message => (
  <Alert variant="danger">Failed to update channel: {message}</Alert>
);

export default class EditChannel extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  };

  isAdmin = Auth.isAdmin();
  channelId = this.props.match.params.id;
  edit = this.channelId !== undefined;

  state = {
    channel: {
      id: '',
      type: 'rest',
      name: '',
      slug: '',
      description: '',
      columns: [],
      response: '',
      company: '',
      meta: {},
    },
    companies: [],
    message: '',
  };

  componentDidMount() {
    if (this.edit) {
      ChannelAPI.get(this.channelId).then(channel => this.setState({ channel }));
    }
    if (this.isAdmin) {
      UserAPI.all().then(companies => {
        this.setState({
          companies,
        });
      });
    }
  }

  handleInputChange = ({ target }) => {
    this.setState(({ channel }) => ({
      channel: { ...channel, [target.name]: target.value },
    }));
  };

  handleMetaInputChange = ({ target }) => {
    this.setState(({ channel }) => ({
      channel: {
        ...channel,
        meta: { ...channel.meta, [target.name]: target.value },
      },
    }));
  };

  selectChannelType = channelType => {
    this.handleInputChange({ target: { name: 'type', value: channelType } });
  };

  updateChannel = event => {
    event.preventDefault();
    const { channel } = this.state;

    ChannelAPI.edit(channel.id, channel)
      .then(() => {
        this.props.history.push({ pathname: '/channels' });
      })
      .catch(err => {
        this.setState({ message: failAlert(err.message) });
      });
  };

  createChannel = event => {
    event.preventDefault();
    const { channel } = this.state;

    ChannelAPI.create(channel)
      .then(() => {
        this.props.history.push({ pathname: '/channels' });
      })
      .catch(err => {
        this.setState({ message: failAlert(err.message) });
      });
  };

  channelForm = channel => {
    switch (channel.type) {
      case 'sftp':
        return (
          <SftpChannelForm
            channel={channel}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
      case null:
        return null;
      default:
        return (
          <RestChannelForm
            channel={channel}
            onChange={this.handleInputChange}
            onMetaChange={this.handleMetaInputChange}
          />
        );
    }
  };

  render() {
    const { channel, companies } = this.state;
    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/channels">Channels</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {this.edit ? 'Edit' : 'New'} channel
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>
            <h5>{this.edit ? 'Update channel' : 'New channel'}</h5>
            {this.edit && (
              <>
                <Badge variant="primary">
                  Last update : {format(channel.updatedAt, 'DD/MM/YYYY')}
                </Badge>
                <ExportChannelButton
                  channelId={channel.id}
                  className="float-right"
                  size="sm"
                  variant="light"
                />
              </>
            )}
            <ChannelStatus channel={channel} />
          </Card.Header>
          <Card.Body className="bg-light">
            <Form onSubmit={this.edit ? this.updateChannel : this.createChannel}>
              {this.edit && (
                <Form.Label>
                  Slug : <Badge variant="secondary">{channel.slug}</Badge>
                </Form.Label>
              )}
              {this.isAdmin && (
                <Form.Group>
                  <Form.Label>Company*</Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="company"
                    className={!channel.company ? 'select-default' : ''}
                    onChange={this.handleInputChange}
                    value={channel.company}
                  >
                    <option value="">Select a company</option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.login}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    placeholder="Example: Delivery"
                    value={channel.name}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    placeholder="Detailed purpose of this channel"
                    value={channel.description}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>Data source*</Form.Label>
                <ChannelTypes
                  selectedType={channel.type}
                  onSelect={this.selectChannelType}
                />
              </Form.Group>
              {this.channelForm(channel)}
              <Button className="btn-block" type="submit">
                {this.edit ? 'Update' : 'Create'}
              </Button>
              {this.state.message}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
