import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

export const STATUS = {
  OK: 'ok',
  CONNEXION_ERROR: 'connexion_error',
};

function ChannelStatus({ channel }) {
  let variant;
  let icon;
  let text;
  let tooltip;

  if (!channel.meta || !channel.meta.lastStatus) {
    return null;
  }

  switch (channel.meta.lastStatus) {
    case STATUS.OK:
      variant = 'success';
      icon = <FaCheck />;
      text = 'sftp';
      tooltip = 'The channel is watching files on the host with success';
      break;

    default:
      variant = 'danger';
      icon = <FaTimes />;
      text = 'sftp';
      tooltip = 'The channel try to watch files on the host but get an error';
      break;
  }
  return (
    <OverlayTrigger overlay={<Tooltip id="tooltip-channel-status">{tooltip}</Tooltip>}>
      <span className="d-inline-block">
        <Badge pill className={`badge-soft-${variant}`}>
          {icon}&nbsp;{text}
        </Badge>
      </span>
    </OverlayTrigger>
  );
}

ChannelStatus.propTypes = {
  channel: PropTypes.shape({
    meta: PropTypes.shape({
      lastStatus: PropTypes.string,
    }),
  }),
};

export default ChannelStatus;
