import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Alert,
  Card,
  Container,
  Form,
  Breadcrumb,
  Col,
} from 'react-bootstrap';
import ChannelAPI from '../../api/Channel';
import UserAPI from '../../api/User';
import Auth from '../../authentication/auth';
import { NavLink } from 'react-router-dom';

export default class ImportChannel extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  isAdmin = Auth.isAdmin();

  state = {
    channel: {
      name: '',
      description: '',
      company: '',
    },
    companies: [],
    uploadInput: null,
  };

  componentDidMount() {
    if (this.isAdmin) {
      UserAPI.all().then(companies => {
        this.setState({
          companies,
        });
      });
    }
  }

  handleInputChange = ({ target }) => {
    this.setState(({ channel }) => ({
      channel: { ...channel, [target.name]: target.value },
    }));
  };

  handleFileChange = uploadInput => {
    this.setState({ uploadInput, body: '' });
  };

  importChannel = event => {
    event.preventDefault();

    const data = new FormData();
    data.append('file', this.state.uploadInput);
    data.append('filename', 'file' + Date.now());
    data.append('company', this.state.channel.company);
    data.append('name', this.state.channel.name);
    data.append('description', this.state.channel.description);

    ChannelAPI.import(data)
      .then(data => {
        // Redirect to the /channels/edit page, or /channels if ID is missing
        if (!data.channel || !data.channel.id) {
          this.props.history.push({ pathname: `/channels` });
        }
        this.props.history.push({ pathname: `/channels/edit/${data.channel.id}` });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          message: <Alert variant="danger">{err.response.data.message}</Alert>,
          uploadInput: null,
        });
      });
  };

  render() {
    const { channel, companies } = this.state;
    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/channels">Channels</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Import channel</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>
            <h5>Import channel</h5>
          </Card.Header>

          <Card.Body className="bg-light">
            <Form onSubmit={this.importChannel}>
              {this.isAdmin && (
                <Form.Group>
                  <Form.Label>Company*</Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="company"
                    className={!channel.company ? 'select-default' : ''}
                    onChange={this.handleInputChange}
                    value={channel.company}
                  >
                    <option value="">Select a company</option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.login}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Override name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Let empty to use configuration's name"
                    value={channel.name}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Override description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    placeholder="Detailed purpose of this channel"
                    value={channel.description}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Form.Label>File*</Form.Label>
                <Form.Control
                  type="file"
                  required
                  ref={ref => (this.uploadInput = ref)}
                  onChange={event =>
                    this.handleFileChange(event.target.files.item(0))
                  }
                />
              </Form.Group>
              <Button className="btn-block" type="submit">
                Import
              </Button>
              {this.state.message}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
