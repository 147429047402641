import BaseAPI from './BaseAPI';

/**
 * Channel API
 * @extends BaseAPI
 */
export default class Channel extends BaseAPI {
  /**
   * Get all the Channels
   *
   * @returns {Promise<Array[Object]>} All the Channels
   */
  static all() {
    return this.execute('get', '/channels');
  }

  /**
   * Get a Channel by its id
   *
   * @param {String} id Id of the channel
   * @returns {Promise<Object>} The Channel
   */
  static get(id) {
    return this.execute('get', `/channels/${id}`);
  }

  /**
   * Create a Channel
   *
   * @param {Object} channel The Channel Object
   * @returns {Promise<Object>} An Channel update response
   */
  static create(channel) {
    return this.execute('post', `/channels`, channel);
  }

  /**
   * Import a Channel
   *
   * @param {FormData} data
   * @returns {Promise<Object>} A channel import response
   */
  static import(data) {
    return this.execute('post', `/channels/import`, data);
  }

  /**
   * Edit a Channel
   *
   * @param {String} id The Channel ID
   * @param {Object} channel The Channel Object
   * @returns {Promise<Object>} An Channel update response
   */
  static edit(id, channel) {
    return this.execute('put', `/channels/${id}`, channel);
  }

  /**
   * Delete a Channel
   *
   * @param {String} id The Channel ID
   * @returns {Promise<Object>} An Channel update response
   */
  static delete(id) {
    return this.execute('delete', `/channels/${id}`);
  }
}
