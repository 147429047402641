import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table } from 'react-bootstrap';
import CodeEditor from '../CodeEditor';
import Help from './../Help';

function ChannelInfo({ channel }) {
  return (
    <div className="sticky-top sticky-sidebar">
      <Card>
        <Card.Header>
          <h5>Channel info</h5>
        </Card.Header>
        <Card.Body>
          {channel !== null && (
            <>
              <h6>Name</h6>
              <p>{channel.name}</p>
              {channel.description && (
                <>
                  <h6>Description</h6>
                  <p>{channel.description}</p>
                </>
              )}
              {channel.columns.length > 0 && (
                <>
                  <h6>
                    Available columns &nbsp;
                    <Help title="Columns">
                      <i>Columns</i> correspond to data sent by channel’s owner,{' '}
                      <b>you can access individual column</b> throughout your calls
                      configuration.
                    </Help>
                  </h6>
                  <Table className="data-table" striped bordered>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Example</th>
                      </tr>
                    </thead>
                    <tbody>
                      {channel.columns.map(({ id, name, example }) => (
                        <tr key={id}>
                          <td>{name}</td>
                          <td>{example}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                </>
              )}

              {channel.response !== '' && (
                <>
                  <h6>
                    Expected response
                    <Help title="Expected response">
                      Example of response you should provide expected by channel’s
                      owner. See <b>Response template</b> for more detail.
                    </Help>
                  </h6>
                  <CodeEditor readOnly code={channel.response} />
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

ChannelInfo.propTypes = {
  channel: PropTypes.object,
};

export default ChannelInfo;
