import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';

function MonitoringFilters({ filters, onFilter }) {
  const onChange = ({ target }) => onFilter(target.name, target.value);
  return (
    <Row>
      <Col>
        <Form.Check
          inline
          type="radio"
          name="ownership"
          value="all"
          checked={filters.ownership === 'all'}
          label="All"
          onChange={onChange}
        />
        <Form.Check
          inline
          type="radio"
          name="ownership"
          value="subscriptions"
          checked={filters.ownership === 'subscriptions'}
          label="Only my subscriptions"
          onChange={onChange}
        />
        <Form.Check
          inline
          type="radio"
          name="ownership"
          value="subscribers"
          checked={filters.ownership === 'subscribers'}
          label="Only my subscribers"
          onChange={onChange}
        />
      </Col>
    </Row>
  );
}

MonitoringFilters.propTypes = {
  filters: PropTypes.shape({
    ownership: PropTypes.string,
  }),
  onFilter: PropTypes.func,
};

export default MonitoringFilters;
