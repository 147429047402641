import React from 'react';
import { Button, Alert, Card, Container, Form } from 'react-bootstrap';
import UserAPI from '../api/User';
import UploadAPI from '../api/Upload';
import Auth from '../authentication/auth';

export default class Upload extends React.Component {
  state = {
    channels: [],
    body: '',
    uploadInput: null,
    channel: '',
  };

  isAdmin = Auth.isAdmin();

  componentDidMount() {
    UserAPI.getChannels().then(channels => {
      this.setState({ channels });
    });
  }

  handleFileChange = uploadInput => {
    this.setState({ uploadInput, body: '' });
  };

  handleChannelChange = channel => {
    this.setState({ channel });
  };

  handleUploadImage = event => {
    event.preventDefault();

    const data = new FormData();
    data.append('file', this.state.uploadInput);
    data.append('filename', 'file' + Date.now());
    data.append('channel', this.state.channel);

    UploadAPI.upload(data)
      .then(data => {
        this.setState({
          body: <Alert variant="success">{data.message}</Alert>,
          channel: '',
          uploadInput: null,
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          body: <Alert variant="danger">{err.response.data.message}</Alert>,
          channel: '',
          uploadInput: null,
        });
      })
      .finally(() => {
        this.uploadInput.value = '';
      });
  };

  render() {
    const { channel, channels } = this.state;
    return (
      <Container>
        <Card>
          <Card.Header>
            <h5>Upload</h5>
          </Card.Header>

          <Card.Body className="bg-light">
            <Form onSubmit={this.handleUploadImage}>
              <Form.Group>
                <Form.Label>Channel</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="channel"
                  className={!channel ? 'select-default' : ''}
                  onChange={event => this.handleChannelChange(event.target.value)}
                  value={channel}
                >
                  <option value="" disabled>
                    Select a channel
                  </option>
                  {channels.map(channel => (
                    <option key={channel.id} value={channel.id}>
                      {this.isAdmin ? `${channel.company.login} - ` : ''}
                      {channel.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>File</Form.Label>
                <Form.Control
                  type="file"
                  required
                  ref={ref => (this.uploadInput = ref)}
                  placeholder="Enter your password"
                  onChange={event =>
                    this.handleFileChange(event.target.files.item(0))
                  }
                />
              </Form.Group>
              {this.state.body}
              <Button type="submit" variant="primary">
                Upload
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
