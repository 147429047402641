import React from 'react';
import PropTypes from 'prop-types';

function BroadcastColumn({ column, handleChange }) {
  return (
    <div className="ecosystem-div-small">
      <label>{column.label}</label>{' '}
      <input
        className="ecosystem-input-small"
        type="text"
        id={column.label}
        value={column.value}
        onChange={event => handleChange(column.label, event.target.value)}
      />
    </div>
  );
}

BroadcastColumn.propTypes = {
  column: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  }),
  handleChange: PropTypes.func,
};

export default BroadcastColumn;
