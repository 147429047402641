import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import AddColumn from '../columns/AddColumn';
import ColumnForm from '../columns/ColumnForm';

export default class Columns extends Component {
  static propTypes = {
    columns: PropTypes.array,
    onChange: PropTypes.func,
  };

  addColumn = column => {
    const { onChange, columns } = this.props;
    onChange({
      target: {
        name: 'columns',
        value: [...columns, column],
      },
    });
  };

  updateColumn = ({ target }, columnId) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const { onChange, columns } = this.props;
    onChange({
      target: {
        name: 'columns',
        value: columns.map(column => {
          if (column.id === columnId) {
            return { ...column, [name]: value };
          }
          return column;
        }),
      },
    });
  };

  deleteColumn = columnId => {
    const { onChange, columns } = this.props;
    onChange({
      target: {
        name: 'columns',
        value: columns.filter(({ id }) => id !== columnId),
      },
    });
  };

  render() {
    const { columns } = this.props;
    return (
      <Form.Group>
        <Form.Label>Columns sent by company*</Form.Label>
        {columns.map(column => (
          <div key={column.id}>
            <ColumnForm {...column} onChange={e => this.updateColumn(e, column.id)}>
              <Col>
                <Button
                  type="button"
                  size="lg"
                  variant="outline-danger"
                  onClick={() => this.deleteColumn(column.id)}
                >
                  <FaTrash />
                </Button>
              </Col>
            </ColumnForm>
            <br />
          </div>
        ))}
        <AddColumn onAdd={this.addColumn} />
      </Form.Group>
    );
  }
}
