import decode from 'jwt-decode';

export const ROLES = {
  ADMIN: 'admin',
  STARTUP: 'startup',
  COMPANY: 'company',
};

/**
 * Authentication class
 *
 * Holds the token in the localStorage
 *
 */

function authenticate(token, role, login) {
  localStorage.setItem('token', token);
  localStorage.setItem('role', role);
  localStorage.setItem('login', login);
}

function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('login');
}

function getToken() {
  return localStorage.getItem('token');
}

function getLogin() {
  return localStorage.getItem('login');
}

function isLoggedIn() {
  const token = getToken();
  try {
    // Check token expiration
    const date = new Date(0);
    const decoded = decode(token);
    date.setUTCSeconds(decoded.exp);
    return date.valueOf() > new Date().valueOf() + 300000; // 5 minutes margin
  } catch (err) {
    return false;
  }
}

function getRole() {
  return localStorage.getItem('role');
}

function isAdmin() {
  return getRole() === ROLES.ADMIN;
}

export default {
  authenticate,
  logout,
  getToken,
  getLogin,
  isLoggedIn,
  getRole,
  isAdmin,
};
