import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  Alert,
  Button,
  Breadcrumb,
  Container,
  Card,
  Col,
  Form,
  Row,
} from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import SubscriptionAPI from '../../../api/Subscription';
import CallForm from './CallForm';
import ChannelInfo from '../ChannelInfo';
import CodeEditor from '../../CodeEditor';
import AvailableVariables from '../AvailableVariables';
import Help from '../../Help';
import ExportSubscriptionButton from '../ExportSubscriptionButton';

const failAlert = message => <Alert variant="danger"> {message}</Alert>;

export default class ConfigureCalls extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  };

  defaultCall = {
    method: 'GET',
    url: '',
    body: '',
    headers: '{\n\t"Content-Type": "application/json"\n}',
    extractors: [],
    workflow: [],
    postWorkflow: [],
  };

  state = {
    subscription: {
      channel: null,
      calls: [this.defaultCall],
      resultTemplate: '',
    },
    message: '',
    availableVariables: [],
  };

  constructor(props) {
    super(props);
    this.subscriptionId = props.match.params.id;
  }

  componentDidMount() {
    SubscriptionAPI.get(this.subscriptionId).then(subscription =>
      this.setState({ subscription }, () => {
        // If no calls yet add a default one
        if (subscription.calls.length === 0) {
          this.addCall();
        }
      })
    );
  }

  updateSubscription = event => {
    event.preventDefault();
    const { subscription } = this.state;

    SubscriptionAPI.edit(subscription.id, subscription)
      .then(() => {
        this.props.history.push({ pathname: '/subscriptions' });
      })
      .catch(err => {
        this.setState({ message: failAlert(err.response.data.message) });
      });
  };

  addCall = () => {
    this.setState(({ subscription }) => ({
      subscription: {
        ...subscription,
        calls: [...subscription.calls, this.defaultCall],
      },
    }));
  };

  updateCall = ({ target }, index) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState(({ subscription }) => ({
      subscription: {
        ...subscription,
        calls: subscription.calls.map((call, currentIndex) => {
          if (currentIndex === index) {
            return { ...call, [name]: value };
          }
          return call;
        }),
      },
    }));
  };

  deleteCall = deleteIndex => {
    this.setState(({ subscription }) => ({
      subscription: {
        ...subscription,
        calls: subscription.calls.filter((_call, index) => {
          return index !== deleteIndex;
        }),
      },
    }));
  };

  updateResultTemplate = resultTemplate => {
    this.setState(({ subscription }) => ({
      subscription: { ...subscription, resultTemplate },
    }));
  };

  showAvailableVariables = callIndex => {
    const { subscription } = this.state;
    // First get columns
    const columnsVariables = subscription.channel.columns.map(
      ({ name }) => `columns.${name}`
    );
    // Then get variables from previous extractors
    const previousCalls = subscription.calls.slice(0, callIndex);
    // Extract every extractors from calls
    const contextVariables = previousCalls.reduce((variables, call) => {
      const extractorsVariables = call.extractors.map(
        ({ name }) => `context.${name}`
      );
      return [...variables, ...extractorsVariables];
    }, []);
    const availableVariables = [...columnsVariables, ...contextVariables];
    this.setState({ availableVariables });
  };

  closeAvailableVariables = () => {
    this.setState({ availableVariables: [] });
  };

  render() {
    const { subscription, availableVariables } = this.state;
    const hasExpectedResponse =
      subscription.channel && subscription.channel.expectedResponse !== '';
    return (
      <Container fluid>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/subscriptions">Subscriptions</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Configure calls</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col>
            <Card className="mb-3">
              <Card.Body>
                <ExportSubscriptionButton subscriptionId={subscription.id} />
                <h4>Configure your calls</h4>
                <p>
                  Configure the sequence of calls to your APIs to fulfill channel’s
                  requirements.
                </p>
              </Card.Body>
            </Card>

            <Form onSubmit={this.updateSubscription}>
              <Form.Group>
                {subscription.calls.map((call, index) => (
                  <CallForm
                    key={index}
                    index={index + 1}
                    {...call}
                    onChange={e => this.updateCall(e, index)}
                    onDelete={() => this.deleteCall(index)}
                    onAvailableVariablesClick={() =>
                      this.showAvailableVariables(index)
                    }
                    availableCalls={subscription.calls}
                  />
                ))}
                <Button type="button" onClick={this.addCall} variant="primary">
                  <FaPlus /> New Call
                </Button>
              </Form.Group>

              {hasExpectedResponse && (
                <Card>
                  <Card.Header>
                    <h6>
                      Response template&nbsp;
                      <Help title="Response template" maxWidth="320px">
                        <p>
                          Use Javascript to create the final response of your calls,{' '}
                          <b>checkout the expected response</b> from channel’s owner.
                        </p>
                        <p>
                          <u>Example :</u>
                        </p>
                        <CodeEditor
                          readOnly
                          code={
                            '<% var output = []; %>\n\n<%-JSON.stringify(output);%>'
                          }
                        />
                      </Help>
                    </h6>

                    <Alert className="mb-4" variant="light" style={{ padding: 0 }}>
                      <p>You also can use variables in your result template.</p>
                      <Button
                        variant="outline-primary"
                        type="button"
                        onClick={() =>
                          this.showAvailableVariables(subscription.calls.length)
                        }
                      >
                        See variables
                      </Button>
                    </Alert>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <CodeEditor
                        code={subscription.resultTemplate}
                        onChange={this.updateResultTemplate}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              )}
              <br />
              <Button type="submit" className="btn-block">
                Update
              </Button>
              <br />
              {this.state.message}
            </Form>
          </Col>

          <Col xs={4}>
            <ChannelInfo channel={subscription.channel} />
          </Col>
        </Row>
        {availableVariables.length > 0 && (
          <AvailableVariables
            variables={availableVariables}
            onClose={this.closeAvailableVariables}
          />
        )}
      </Container>
    );
  }
}
