import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import CodeEditor from '../../CodeEditor';
import Columns from './../columns/Columns';

export default class RestChannelForm extends Component {
  static propTypes = {
    channel: PropTypes.shape({
      response: PropTypes.string,
      columns: PropTypes.array,
    }),
    onChange: PropTypes.func,
  };

  render() {
    const { channel, onChange } = this.props;
    return (
      <>
        <Columns columns={channel.columns} onChange={onChange} />
        <Form.Group>
          <Form.Label>Example of expected response</Form.Label>
          <CodeEditor
            code={channel.response}
            onChange={value =>
              onChange({
                target: { name: 'response', value },
              })
            }
          />
        </Form.Group>
      </>
    );
  }
}
