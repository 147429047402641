import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';

export default class WorkflowForm extends Component {
  static propTypes = {
    condition: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    action: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    step: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    availableCalls: PropTypes.array,
    children: PropTypes.array,
  };

  render() {
    const {
      condition,
      action,
      step,
      onChange,
      availableCalls,
      children = null,
    } = this.props;

    return (
      <Form.Row style={{ alignItems: 'flex-end' }}>
        <Col xs={7}>
          <Form.Label>If</Form.Label>
          <Form.Control
            required
            type="text"
            name="condition"
            value={condition}
            placeholder="If"
            onChange={onChange}
          />
        </Col>
        <Col xs={2}>
          <Form.Label>Action</Form.Label>
          <Form.Control
            as="select"
            required
            name="action"
            onChange={onChange}
            value={action}
          >
            <option value="stop">Stop</option>
            <option value="goto">Go to</option>
          </Form.Control>
        </Col>

        {action === 'goto' && (
          <Col>
            <Form.Label>Go to</Form.Label>
            <Form.Control
              as="select"
              required
              name="step"
              onChange={onChange}
              value={step}
              className={!step ? 'select-default' : ''}
            >
              <option value="">Select a call</option>
              {availableCalls.map((_call, index) => (
                <option key={index} value={index + 1}>
                  Call n°{index + 1}
                </option>
              ))}
            </Form.Control>
          </Col>
        )}
        {children}
      </Form.Row>
    );
  }
}
