import React, { Component } from 'react';
import MailerSettingForm from './MailerSettingForm';

import { Card, Col, Container, Row } from 'react-bootstrap';

export default class Settings extends Component {
  render() {
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <Card>
              <Card.Header>
                <h5>Mailing</h5>
              </Card.Header>
              <Card.Body className="bg-light">
                <MailerSettingForm />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
