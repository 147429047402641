import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Button, Overlay, Tooltip } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import ExtractorForm from './ExtractorForm';

export default class AddExtractor extends Component {
  static propTypes = {
    onAdd: PropTypes.func,
  };

  state = {
    part: 'body',
    name: '',
    path: '',
    anonymize: false,
  };

  handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  onAdd = event => {
    event.preventDefault();
    event.stopPropagation();
    const { name, path, part, anonymize } = this.state;
    this.props.onAdd({ part, name, path, anonymize });
    this.setState({ part: 'body', name: '', path: '', anonymize: false });
  };

  render() {
    const { name, path, anonymize } = this.state;
    return (
      <div>
        <ExtractorForm
          name={name}
          path={path}
          anonymize={anonymize}
          onChange={this.handleInputChange}
          isAddLine={true}
        >
          <Col xs="auto">
            <Overlay
              placement="top"
              target={this.buttonRef}
              show={name !== '' && path !== ''}
            >
              {props => (
                <Tooltip {...props} show={props.show.toString()}>
                  Don’t forget to confirm
                </Tooltip>
              )}
            </Overlay>
            <Button
              ref={ref => (this.buttonRef = ref)}
              type="submit"
              size="lg"
              variant="light"
              onClick={this.onAdd}
            >
              <FaPlus />
            </Button>
          </Col>
        </ExtractorForm>
      </div>
    );
  }
}
