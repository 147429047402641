import React from 'react';
import PropTypes from 'prop-types';

function BroadcastResponse({ response }) {
  return (
    <div>
      <label>{response.from}</label>
      <pre>{JSON.stringify(response.response)}</pre>
    </div>
  );
}

BroadcastResponse.propTypes = {
  response: PropTypes.shape({
    from: PropTypes.string,
    response: PropTypes.object,
  }),
};

export default BroadcastResponse;
