import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaRegQuestionCircle } from 'react-icons/fa';
import CodeEditor from './CodeEditor';

const popover = (
  <Popover id="popover-basic" title="Anonymization">
    We hide specific detail of your data in logging, monitoring etc..
    <br />
    <br />
    Example :
    <br />
    <CodeEditor
      readOnly
      code={JSON.stringify(
        {
          firstname: '*****',
          lastname: '*****',
        },
        null,
        2
      )}
    />
  </Popover>
);

function AnonymizeInput({
  name,
  checked = false,
  onChange,
  label = 'Anonymize',
  width = 165,
}) {
  return (
    <InputGroup style={{ width }} className="anonymize-input">
      <InputGroup.Prepend>
        <InputGroup.Checkbox name={name} checked={checked} onChange={onChange} />
      </InputGroup.Prepend>

      <FormControl disabled defaultValue={label} />

      <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
        <InputGroup.Append>
          <InputGroup.Text>
            <FaRegQuestionCircle />
          </InputGroup.Text>
        </InputGroup.Append>
      </OverlayTrigger>
    </InputGroup>
  );
}

AnonymizeInput.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  width: PropTypes.number,
};

export default AnonymizeInput;
