import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaPlus, FaEdit, FaTrashAlt, FaCloudUploadAlt } from 'react-icons/fa';
import Auth from '../../authentication/auth';
import ChannelAPI from '../../api/Channel';
import UserAPI from '../../api/User';
import {
  Table,
  Container,
  Card,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';
import ChannelStatus from './ChannelStatus';
import ExportChannelButton from './ExportChannelButton';

export default class Channels extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  state = {
    channels: [],
    showDeleteConfirm: false,
    deletingChannel: null,
  };

  constructor() {
    super();
    this.isAdmin = Auth.isAdmin();
  }

  componentDidMount() {
    this.loadChannels();
  }

  loadChannels() {
    UserAPI.getChannels().then(channels => this.setState({ channels }));
  }

  createNewChannel = () => {
    this.props.history.push({ pathname: '/channels/create' });
  };

  importChannel = () => {
    this.props.history.push({ pathname: '/channels/import' });
  };

  updateChannel = channelId => {
    this.props.history.push({ pathname: `/channels/edit/${channelId}` });
  };

  askDeleteConfirm = channelId => {
    this.setState({ showDeleteConfirm: true, deletingChannel: channelId });
  };

  closeDeleteConfirm = () => {
    this.setState({ showDeleteConfirm: false, deletingChannel: null });
  };

  deleteChannel = channelId => {
    ChannelAPI.delete(channelId).finally(() => {
      this.closeDeleteConfirm();
      this.loadChannels();
    });
  };

  truncate(str, maxLength) {
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  }

  render() {
    const { channels, showDeleteConfirm, deletingChannel } = this.state;
    return (
      <Container>
        <Card>
          <Card.Header>
            <Row className="align-items-center">
              <Col>
                <h5>Channels</h5>
              </Col>
              <Col xs="auto">
                <ButtonGroup aria-label="New channel options">
                  <Button onClick={this.createNewChannel} variant="light">
                    <FaPlus /> New Channel
                  </Button>
                  &nbsp;
                  <Button onClick={this.importChannel} variant="light">
                    <FaCloudUploadAlt /> Import
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body style={{ padding: 0 }}>
            <Table size="sm">
              <thead>
                <tr>
                  {this.isAdmin && <th>Company</th>}
                  <th>Name</th>
                  <th>Slug</th>
                  <th>Description</th>
                  <th style={{ width: '4em' }} />
                  <th style={{ width: '20em' }} />
                </tr>
              </thead>
              <tbody>
                {channels.map(channel => (
                  <tr key={channel.id}>
                    {this.isAdmin && <td>{channel.company.login}</td>}
                    <td>{channel.name}</td>
                    <td>{channel.slug}</td>
                    <td>{this.truncate(channel.description, 40)}</td>
                    <td>
                      <ChannelStatus channel={channel} />
                    </td>
                    <td>
                      <ButtonToolbar className="float-right">
                        <ExportChannelButton
                          channelId={channel.id}
                          variant="light"
                        />
                        &nbsp;
                        <Button
                          onClick={() => this.updateChannel(channel.id)}
                          variant="inline-primary"
                        >
                          <FaEdit /> Edit
                        </Button>
                        &nbsp;
                        <Button
                          onClick={() => this.askDeleteConfirm(channel.id)}
                          variant="inline-danger"
                        >
                          <FaTrashAlt /> Delete
                        </Button>
                      </ButtonToolbar>
                    </td>
                  </tr>
                ))}
                {channels.length === 0 && (
                  <tr>
                    <td>
                      <h6>No channels yet.</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Modal show={showDeleteConfirm} onHide={this.closeDeleteConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Channel</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this channel ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeDeleteConfirm}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => this.deleteChannel(deletingChannel)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
