import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaCheck, FaRegClock } from 'react-icons/fa';
import { Badge } from 'react-bootstrap';

export const STATUS = {
  APPROVED: 'approved',
  AWAITING_APPROVAL: 'awaiting_approval',
  REJECTED: 'rejected',
};

function SubscriptionStatus({ status }) {
  let variant;
  let icon;
  let text;
  switch (status) {
    case STATUS.APPROVED:
      variant = 'success';
      icon = <FaCheck />;
      text = 'Approved';
      break;
    case STATUS.AWAITING_APPROVAL:
      variant = 'warning';
      icon = <FaRegClock />;
      text = 'Awaiting approval';
      break;

    default:
      variant = 'danger';
      icon = <FaTimes />;
      text = 'Rejected';
      break;
  }
  return (
    <Badge pill className={`badge-soft-${variant}`}>
      {icon} {text}
    </Badge>
  );
}

SubscriptionStatus.propTypes = {
  status: PropTypes.string,
};

export default SubscriptionStatus;
