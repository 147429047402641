import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
  Alert,
  Button,
  Container,
  Card,
  Col,
  Form,
  Breadcrumb,
  Row,
} from 'react-bootstrap';
import SubscriptionAPI from '../../api/Subscription';
import ChannelAPI from '../../api/Channel';
import UserAPI from '../../api/User';
import Auth from '../../authentication/auth';

const failAlert = message => <Alert variant="danger"> {message}</Alert>;

export default class NewSubscription extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  state = {
    channels: [],
    companies: [],
    subscriber: '',
    selectedChannelId: '',
    channel: null,
    message: '',
  };

  constructor() {
    super();
    this.isAdmin = Auth.isAdmin();
    this.login = Auth.getLogin();
  }

  componentDidMount() {
    ChannelAPI.all().then(channels => {
      this.setState({
        channels: channels.filter(({ company }) => company.login !== this.login),
      });
    });
    if (this.isAdmin) {
      UserAPI.all().then(companies => {
        this.setState({ companies });
      });
    }
  }

  createSubscription = event => {
    event.preventDefault();
    const { channel, subscriber } = this.state;

    const successMessage = {
      flash: {
        type: 'success',
        message: "Subscription created, awaiting channel's owner approval.",
      },
    };

    SubscriptionAPI.create({ channel, subscriber })
      .then(() => {
        this.props.history.push({
          pathname: '/subscriptions',
          state: successMessage,
        });
      })
      .catch(err => {
        this.setState({ message: failAlert(err.response.data.message) });
      });
  };

  selectChannel = async ({ target }) => {
    if (target.value !== '') {
      this.setState({
        selectedChannelId: target.value,
        channel: this.state.channels.find(({ id }) => id === target.value),
      });
    } else {
      this.setState({ selectedChannelId: '', channel: null });
    }
  };

  selectSubscriber = ({ target }) => {
    this.setState({ subscriber: target.value });
  };

  render() {
    const {
      channels,
      companies,
      selectedChannelId,
      channel,
      subscriber,
    } = this.state;

    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <NavLink to="/subscriptions">Subscriptions</NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>New subscription</Breadcrumb.Item>
        </Breadcrumb>

        <Card>
          <Card.Header>
            <h5>New subscription</h5>
          </Card.Header>

          <Card.Body className="bg-light">
            <Form onSubmit={this.createSubscription}>
              <Form.Group>
                <Form.Label>Subscribe to *</Form.Label>
                <Form.Control
                  as="select"
                  required
                  name="selectedChannel"
                  className={selectedChannelId === '' ? 'select-default' : ''}
                  onChange={this.selectChannel}
                  value={selectedChannelId}
                >
                  <option value="" disabled>
                    Select a channel
                  </option>
                  {channels.map(channel => (
                    <option key={channel.id} value={channel.id}>
                      {channel.company.login} - {channel.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {channel !== null && (
                <div className="informations">
                  <h6>Channel</h6>
                  <Row>
                    <Col xs={2}>
                      <p className="information-title">Company</p>
                    </Col>
                    <Col className="information">{channel.company.login}</Col>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <p className="information-title">Name</p>
                    </Col>
                    <Col className="information">{channel.name}</Col>
                  </Row>
                  <Row>
                    <Col xs={2}>
                      <p className="information-title">Description</p>
                    </Col>
                    <Col className="information">{channel.description}</Col>
                  </Row>
                  <br />
                </div>
              )}

              {this.isAdmin && companies.length > 0 && (
                <Form.Group>
                  <Form.Label>Subscriber*</Form.Label>
                  <Form.Control
                    as="select"
                    required
                    name="subscriber"
                    className={subscriber === '' ? 'select-default' : ''}
                    onChange={this.selectSubscriber}
                    value={subscriber}
                  >
                    <option value="" disabled>
                      Select a subscriber
                    </option>
                    {companies.map(company => (
                      <option key={company.id} value={company.id}>
                        {company.login}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Button type="submit">Ask approval</Button>
              <br />
              {this.state.message}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
